import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Subscription/';

const getSubscriptionById = async (subscriptionId) => axiosInstance.get(`${ENDPOINTORIGIN}GetSubscriptionById?subscriptionId=${subscriptionId}`, constants.REQUIRE_INTERCEPTORS);

const getSubscriptionByUserId = async (userId) => axiosInstance.get(`${ENDPOINTORIGIN}getSubscriptionByUserId?userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

const getUserTransactionHistory = async (userId) => axiosInstance.get(`${ENDPOINTORIGIN}GetUserSubscriptionHistory?userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

const getPayPalSubscriptionDetails = async (userId) => axiosInstance.get(`${ENDPOINTORIGIN}GetPayPalSubscriptionDetails?userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

const createSubscription = async (subscriptionResource) => axiosInstance.post(`${ENDPOINTORIGIN}CreateSubscription`, subscriptionResource, constants.REQUIRE_INTERCEPTORS);

const updateSubscription = async (subscription, loggedInUser) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateSubscription`, { subscription, loggedInUser }, constants.REQUIRE_INTERCEPTORS);

const cancelSubscription = async (subscriptionId, userId) => axiosInstance.post(`${ENDPOINTORIGIN}CancelSubscription?subscriptionId=${subscriptionId}&userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getSubscriptionById,
  getSubscriptionByUserId,
  getUserTransactionHistory,
  getPayPalSubscriptionDetails,
  createSubscription,
  updateSubscription,
  cancelSubscription,
};