/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// ASSETS
import { TbUserEdit, TbUsers, TbListSearch, TbMessage, TbCake, TbShieldCheck } from 'react-icons/tb'; // eslint-disable-line
import { Modal, Button as BSButton, ButtonGroup, Fade } from 'react-bootstrap'; // eslint-disable-line
import BannerImage from '../../../../assets/img/banner-image_default.png';
// import BusinessGrowthBackgroundTexture from '../../../../assets/img/background-texture_business-growth.png';
import DefaultMemberProfileImage from '../../../../assets/client/default-member-profile-image.png';
// COMPONENTS
import Banner from '../../../../components/Banner';
import CustomBlock from '../../../../components/CustomBlock';
import Overlay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import FormBlock from '../../../../components/FormBlock';
import SelectBlock from '../../../../components/SelectBlock';
// TABS
import MemberDetailsTab from './tabs/MemberDetailsTab';
import PostsTab from './tabs/PostsTab';
import CommunityTab from './tabs/CommunityTab';
import EngagePagesTab from './tabs/EngagePagesTab';
// MODALS
import PayPalSubscriptionModal from './modals/PayPalSubscriptionModal';
import AccountSettingsModal from './modals/AccountSettingsModal';
import AlertsModal from './modals/AlertsModal';
// SERVICES AND HELPERS
import * as membershipService from '../../../../services/management/membershipService';
import * as subscriptionService from '../../../../services/subscription/subscriptionService';
import * as UserService from '../../../../services/management/userService';
import * as authRedux from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';

const profileTabs = {
  profile: {
    label: 'Profile',
    icon: <TbUserEdit strokeWidth={2.5} size={20} />,
    restricted: false,
  },
  post: {
    label: 'Posts',
    icon: <TbMessage strokeWidth={2.5} size={20} />,
    restricted: ['Registered User', 'Engaged Subscriber'],
  },
  community: {
    label: 'Community',
    icon: <TbUsers strokeWidth={2.5} size={20} />,
    restricted: ['Registered User', 'Engaged Subscriber'],
  },
  'engage-pages': {
    label: 'Engage Pages',
    icon: <TbListSearch strokeWidth={2.5} size={22} />,
    restricted: ['Registered User'],
  },
};

const ProfilePage = (props) => {
  const { auth, showAlert, history, logout } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [membership, setMembership] = useState([]);
  const [user, setUser] = useState([]);
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'profile');
  const [membershipProfile, setMembershipProfile] = useState([]);

  const [userSubscription, setUserSubscription] = useState([]);
  // MODALS
  const [showPayPalSubscriptionModal, setShowPayPalSubscriptionModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showAlertsModal, setShowAlertsModal] = useState(false);

  useEffect(() => {
    getMembership();

    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, []);

  useEffect(() => {
    if (!membershipProfile.length > 0) {
      getMembershipProfile();
    }
  }, []);

  const getMembershipProfile = async () => {
    setIsLoading(true);
    try {
      const userId = auth.user.id;
      const userProfileData = await membershipService.getMembershipProfileByUserId(userId);
      console.log('MEMBERSHIP PROFILE\n', userProfileData);

      setMembershipProfile(userProfileData);
    } catch (ex) {
      // showAlert({ text: ex.message, state: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const getMembership = async () => {
    setIsLoading(true);
    try {
      const userId = auth.user.id;
      const member = await membershipService.getMembershipByUserId(userId);
      setMembership(member);
    } catch (ex) {
      // showAlert({ text: ex.message, state: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMembershipLogout = () => {
    logout();
    history.push('/');
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
    localStorage.setItem('activeTab', key);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Profile"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="profile-page main-content my-0 pb-90 gap-5">
        {/* BACKGROUND */}
        <CustomBlock className="profile-overview--background" />

        <CustomBlock className="profile-overview w-100">
          <CustomBlock className="content-section position-relative flex-column align-items-start mx-auto mt-30 p-0">
            {/* PROFILE ACTIONS */}
            <CustomBlock className="profile-overview--actions d-flex justify-content-end flex-wrap w-100 gap-2 mb-30">
              {(membershipProfile.membershipTier !== 'Registered User' && membershipProfile.payPalTransactionId) && (
                <>
                  <Button
                    text="Messages"
                    className="size-xs info--bg--gradient fit-content user-select-none"
                    size="sm"
                    // iconLeft={<TbBell strokeWidth={2.25} className="size-sm white--clr mr-2" />}
                    onClick={() => setShowAlertsModal(true)}
                    style={{ borderRadius: '300px' }}
                  />
                  <Button
                    text="Account"
                    className="size-xs primary--bg--gradient fit-content user-select-none"
                    size="sm"
                    // iconLeft={<TbSettings strokeWidth={2.25} className="size-sm white--clr mr-2" />}
                    onClick={() => setShowSettingsModal(true)}
                    style={{ borderRadius: '300px' }}
                  />
                </>
              )}

              <Button
                text="Logout"
                className="size-xs danger--bg--gradient fit-content user-select-none"
                size="sm"
                // iconLeft={<TbLogout strokeWidth={2.5} className="size-sm white--clr mr-2" />}
                onClick={handleMembershipLogout}
                style={{ borderRadius: '300px' }}
              />
            </CustomBlock>

            {/* MEMBERSHIP BUTTON */}
            {(membershipProfile.membershipTier === 'Registered User' || membershipProfile.isEligible) && (
              <CustomBlock className="profile-overview--membership-btn d-flex align-items-center justify-content-center mb-30" onClick={() => setShowPayPalSubscriptionModal(true)}>
                <span className="size-lg fw-600 light--clr lh-normal py-30 user-select-none text-uppercase">
                  Become a Member!
                </span>
              </CustomBlock>
            )}

            {/* PROFILE MAIN */}
            <CustomBlock className="profile-overview--main xlight-grey--sbg--gradient flex-column align-items-start w-100 mx-auto px-20 py-40">
              <CustomBlock className="overview-section d-flex align-items-center flex-wrap gap-30">
                <CustomBlock
                  className="member-profile-image"
                  style={{
                    backgroundImage: membershipProfile && membershipProfile.imageFilePath
                      ? `url(${process.env.REACT_APP_API_URL}Attachments${membershipProfile.imageFilePath})`
                      : `url(${DefaultMemberProfileImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '300px',
                  }}
                />

                <CustomBlock className="d-flex flex-column gap-1">
                  <CustomBlock className="d-flex flex-column gap-2">
                    <h2 className="d-flex align-items-baseline flex-wrap gap-1 size-mdlg primary-animated-gradient--clr fw-500 user-select-none lh-normal">
                      Welcome, <span className="size-xl fw-700 lh-normal">{membershipProfile.firstName} {membershipProfile.lastName}</span>
                    </h2>

                    {membershipProfile.membershipTier && (
                    <CustomBlock className="d-flex align-items-center gap-2 my-10">
                      <p
                        className="badge secondary-animated-gradient--bg light--clr size-xs fw-500 px-3 py-2 fit-content lh-normal text-uppercase user-select-none"
                        style={{ borderRadius: '6px' }}
                      >
                        {membershipProfile.membershipTier}
                      </p>
                    </CustomBlock>
                    )}

                    {membershipProfile.dateCreated && (
                    <CustomBlock className="d-flex align-items-center flex-wrap gap-2">
                      <TbCake strokeWidth={1.8} className="size-sm primary-lighter--clr" />
                      <p className="size-xs primary-lighter--clr fw-600 fit-content">
                        <span className="fw-400">Registered</span> {moment(membershipProfile.dateCreated).format('M/D/YYYY')}
                      </p>
                    </CustomBlock>
                    )}

                    {membershipProfile.payPalSubscriptionId && (
                    <CustomBlock className="d-flex align-items-center gap-2">
                      <TbShieldCheck strokeWidth={2} className="size-sm primary-lighter--clr" />
                      <p className="size-xs primary-lighter--clr fw-600 fit-content">
                        <span className="fw-400">Membership</span> {moment(membershipProfile.membershipDateCreated).format('M/D/YYYY')}
                      </p>
                    </CustomBlock>
                    )}
                  </CustomBlock>
                </CustomBlock>
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>

        {/* PROFILE TABS */}
        <CustomBlock className="position-relative w-100">
          <CustomBlock className="profile-tabs row w-100 mx-0 my-30">
            <ButtonGroup className="w-100">
              {Object.entries(profileTabs).map(([key, { label, icon, restricted }]) => {
                const isDisabled = restricted && restricted.includes(membershipProfile.membershipTier);
                return (
                  <CustomBlock
                    key={key}
                    className={`w-100 size-sm border-0 fw-500 text-uppercase py-15 ${isDisabled ? 'disabled' : ''} ${
                      activeTab === key ? 'active primary-animated-gradient--bg' : 'bg-transparent dark--clr'
                    }`}
                    onClick={!isDisabled ? () => handleTabSelect(key) : undefined}
                    style={{
                      boxShadow: 'none',
                      cursor: isDisabled ? 'not-allowed' : 'pointer',
                    }}
                  >
                    <CustomBlock className="tab d-flex align-items-center justify-content-center gap-2">
                      {icon}
                      <span className="size-sm lh-normal">{label}</span>
                    </CustomBlock>
                  </CustomBlock>
                );
              })}
            </ButtonGroup>
          </CustomBlock>
        </CustomBlock>

        {/* TAB CONTENT */}
        <CustomBlock className="content-section mt-10 p-0 pb-90">
          <CustomBlock className="content-container--card w-100">
            {activeTab === 'profile' && (
            <Fade in={activeTab === 'profile'}>
              <div>
                <MemberDetailsTab
                  title="Profile"
                  auth={auth}
                  setIsLoading={setIsLoading}
                  membership={membership}
                  setMembership={setMembership}
                  membershipProfile={membershipProfile}
                  setMembershipProfile={setMembershipProfile}
                  user={auth.user}
                  refreshMembership={getMembershipProfile}
                  {...props}
                />
              </div>
            </Fade>
            )}

            {activeTab === 'post' && (
            <Fade in={activeTab === 'post'}>
              <div>
                <PostsTab
                  title="Posts"
                  auth={auth}
                  {...props}
                />
              </div>
            </Fade>
            )}

            {activeTab === 'community' && (
            <Fade in={activeTab === 'community'}>
              <div>
                <CommunityTab
                  title="Community"
                  setIsLoading={setIsLoading}
                  setMembership={setMembership}
                  membership={membership}
                  {...props}
                />
              </div>
            </Fade>
            )}

            {activeTab === 'engage-pages' && (
            <Fade in={activeTab === 'engage-pages'}>
              <div>
                <EngagePagesTab
                  title="Engage Pages"
                  setIsLoading={setIsLoading}
                  setMembership={setMembership}
                  membership={membership}
                  {...props}
                />
              </div>
            </Fade>
            )}
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>

      {/* SETTINGS MODAL */}
      {showSettingsModal && (
        <AccountSettingsModal auth={auth} membership={membership} showSettingsModal={showSettingsModal} setShowSettingsModal={setShowSettingsModal} />
      )}

      {/* PAYPAL SUBSCRIPTION MODAL */}
      {membershipProfile.id && (
        <PayPalSubscriptionModal
          show={showPayPalSubscriptionModal}
          onClose={() => setShowPayPalSubscriptionModal(false)}
          auth={auth}
          membership={membershipProfile}
        />
      )}

      {/* ALERTS MODAL */}
      <AlertsModal
        auth={auth}
        showAlertsModal={showAlertsModal}
        setShowAlertsModal={setShowAlertsModal}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...authRedux.actions, ...alert.actions })(ProfilePage);