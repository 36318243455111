import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Post/';

const getAllPosts = async (subscriptionId) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllPosts?subscriptionId=${subscriptionId}`, constants.REQUIRE_INTERCEPTORS);

const getAllActivePosts = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllActivePosts`, constants.REQUIRE_INTERCEPTORS);

const getPostById = async (postId) => axiosInstance.get(`${ENDPOINTORIGIN}GetPostById?postId=${postId}`, constants.REQUIRE_INTERCEPTORS);

const getPostsByUserId = async (userId) => axiosInstance.get(`${ENDPOINTORIGIN}GetPostsByUserId?userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

const savePost = async (postResource) => axiosInstance.post(`${ENDPOINTORIGIN}SavePost`, postResource, constants.REQUIRE_INTERCEPTORS);

const createPost = async (postResource) => axiosInstance.post(`${ENDPOINTORIGIN}CreatePost`, postResource, constants.REQUIRE_INTERCEPTORS);

const updatePost = async (post) => axiosInstance.put(`${ENDPOINTORIGIN}UpdatePost`, post, constants.REQUIRE_INTERCEPTORS);

const deletePost = async (postId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeletePost?postId=${postId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllPosts,
  getAllActivePosts,
  getPostById,
  getPostsByUserId,
  savePost,
  createPost,
  updatePost,
  deletePost,
};