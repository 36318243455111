/* eslint-disable camelcase */
import moment from 'moment';
import constants from '../constants/constants';

/* eslint-disable no-restricted-syntax */
const getInputClasses = (formik, fieldname) => {
  if (formik.touched[fieldname] && formik.errors[fieldname]) {
    return 'error';
  }
  if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    return '';
  }

  return '';
};

const convertJsonToFormData = (json) => {
  const form_data = new FormData();
  for (const key in json) {
    if (Array.isArray(json[key])) { json[key].forEach((item) => form_data.append(key, item)); } else { form_data.append(key, (json[key] === null) ? '' : json[key]); }
  }

  return form_data;
};

const setToOptionModel = (name, value) => ({ label: name, value });

const reOrderDnd = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((x, i) => ({ ...x, sortOrder: i }));
};

const mapContentSectionName = (type) => {
  switch (type) {
    case constants.CONTENT_SECTION.ContentSectionTypeDefault:
      return 'Content Section (Default)';
    case constants.CONTENT_SECTION.ContentSectionTypeReversed:
      return 'Content Section (Reversed)';
    case constants.CONTENT_SECTION.ContentSectionTypeFullWidth:
      return 'Content Section Full Width';
    case constants.CONTENT_SECTION.ContentSectionTypeFullWidthPadded:
      return 'Content Section Full Width (Padded)';
    case constants.CONTENT_SECTION.ContentSectionTypeTextOnly:
      return 'Content Section (Text Only)';
    case constants.CONTENT_SECTION.ContentSectionTypeCard:
      return 'Content Card';
    case constants.CONTENT_SECTION.ContentSectionTypeBanner:
      return 'Content Banner';
    case constants.CONTENT_SECTION.ContentSectionDynamicBanner:
      return 'Banner';
    case constants.CONTENT_SECTION.ContentSectionCustom:
      return 'Custom';
    default:
      return '';
  }
};

const copyToClipBoard = (copyText) => {
  const aux = document.createElement('input');
  aux.setAttribute('value', copyText);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
};

const countdownMessage = (expiryDate) => {
  if (!expiryDate) {
    return '';
  }

  const date = moment().format('MM/DD/YYYY hh:mm:ss a');
  const diffr = moment.duration(moment(new Date(expiryDate)).diff(new Date(date)));
  const hours = parseInt(diffr.asHours(), 10);
  const minutes = parseInt(diffr.minutes(), 10);
  const seconds = parseInt(diffr.seconds(), 10);
  const currentDuration = Math.floor((hours * 60 * 60 + minutes * 60 + seconds) / 3600);

  if (currentDuration > 24) {
    return `${Math.ceil(currentDuration / 24)} Days Left`;
  }
  if (currentDuration === 24) {
    return `${Math.floor(currentDuration / 24)} Day Left`;
  }
  if (currentDuration > 12 && currentDuration !== 24) {
    return 'Expires Soon';
  }
  if ((currentDuration < 12 && currentDuration > 0) || currentDuration === 0) {
    return 'Expires Today';
  }
  if (currentDuration === -1 || currentDuration < -1) {
    return '';
  }

  return 'Limited-Time Only';
};

const dateFilterListRenderer = (v) => {
  if (v[0] && v[1]) {
    return `Start Date: ${v[0]} to End Date: ${v[1]}`;
  }
  if (v[0]) {
    return `Start Date: ${v[0]}`;
  }
  if (v[1]) {
    return `End Date: ${v[1]}`;
  }

  return false;
};

const dateFilterLogic = (date, filters) => {
  const check = new Date(date);
  let from = new Date(filters[0]);
  let to = new Date(filters[1]);

  from.setDate(from.getDate() + 1);
  to.setDate(to.getDate() + 1);
  from = new Date(from).setHours(0, 0, 0, 0);
  to = new Date(to).setHours(23, 59, 59, 59);

  if (filters[0] && filters[1] && check >= to && check <= from) {
    return true;
  }
  if (filters[0] && check >= to) {
    return true;
  }
  if (filters[1] && check <= from) {
    return true;
  }
  return false;
};

// Extracts date (month, day, year) from input string using regex,
// returns object with values or null
const extractDateFromString = (str) => {
  const monthRegex = /(jan(?:uary)?|feb(?:ruary)?|mar(?:ch)?|apr(?:il)?|may|jun(?:e)?|jul(?:y)?|aug(?:ust)?|sep(?:tember)?|oct(?:ober)?|nov(?:ember)?|dec(?:ember)?)/gi;
  const dayRegex = /\d{1,2}/;
  const yearRegex = /\d{4}/;

  const monthMatch = str.match(monthRegex);
  const dayMatch = str.match(dayRegex);
  const yearMatch = str.match(yearRegex);

  if (monthMatch && dayMatch) {
    const month = monthMatch[0].toLowerCase();
    const day = dayMatch[0];
    const year = yearMatch ? yearMatch[0] : null;

    return { month, day, year };
  }

  return null;
};

const extractDate = (input) => {
  const match = input.match(/^(\w+),\s(\w+)\s(\d+),\s(\d+),\s(\d+):(\d+)\s(am|pm)\s-\s(\d+):(\d+)\s(am|pm)$/);

  return {
    weekDay: match[1],
    month: match[2],
    day: match[3],
    year: match[4]
  };
};

const extractTimeFromString = (input) => {
  const timeRegex = /(\d{1,2}:\d{2} [ap]m)/gi;
  const times = input.match(timeRegex);

  if (!times) {
    return null;
  }

  const time = {};
  if (times.length === 1) {
    time.startValue = times[0].slice(0, 5);
    time.startMeridian = times[0].slice(5);
  } else {
    time.startValue = times[0].slice(0, 5);
    time.startMeridian = times[0].slice(5).toUpperCase();
    time.endValue = times[1].slice(0, 5);
    time.endMeridian = times[1].slice(5).toUpperCase();
  }

  return time;
};

const extractLocationData = (input) => {
  const addressRegex = /^(.+),/;
  const addressMatch = input.match(addressRegex);

  const iframeRegex = /src="(.+?)"/;
  const iframeMatch = input.match(iframeRegex);

  if (!addressMatch || !iframeMatch) {
    return null;
  }

  const address = addressMatch[1];
  const iframeSrc = iframeMatch[1];

  const [street, city, state, zipcode] = address.split(', ');

  return {
    street,
    city,
    state,
    zipcode,
    iframeSrc
  };
};

const extractDescription = (input) => {
  const linkRegex = /\[(.*?)\]\((.*?)\)/g;
  const links = input.match(linkRegex);
  const output = { description: input.replace(linkRegex, '').trim() };

  if (links) {
    for (let i = 0; i < links.length; i += 1) {
      const link = links[i];
      const label = link.match(/\[(.*?)\]/)[1];
      const url = link.match(/\((.*?)\)/)[1];

      output[`link${i + 1}`] = { label, url };
    }
  }

  return output;
};

const getMembershipOptions = () => [
  { value: 'Registered User', label: 'Registered User' },
  { value: 'Engaged Subscriber', label: 'Engaged Subscriber' },
  { value: 'Engaged Sponsor', label: 'Engaged Sponsor' },
  { value: 'Engaged Member', label: 'Engaged Member' },
  { value: 'Engaged Provider', label: 'Engaged Provider' },
];

const getEventStatus = (start, end, status = 'active') => {
  const normalizedStatus = status?.trim().toLowerCase().replace(/\s+/g, '-');
  const now = moment();

  if (normalizedStatus === 'cancelled') {
    return 'Cancelled';
  }

  if (!end) {
    return now.isBefore(moment(start)) ? 'Upcoming' : 'In-Progress';
  }

  if (now.isBefore(moment(start))) {
    return 'Upcoming';
  }

  if (now.isBetween(moment(start), moment(end), null, '[]')) {
    return 'In-Progress';
  }

  return 'Past';
};

const getEventStatusColor = (status = 'active') => {
  const normalizedStatus = status?.trim().toLowerCase().replace(/\s+/g, '-');

  const statusColors = {
    upcoming: '#0891b2', // BLUE
    past: '#64748b', // SLATE GRAY
    'in-progress': '#16a34a', // GREEN
    cancelled: '#e11d48', // RED
    default: '#3f3f46', // ZINC
  };

  return statusColors[normalizedStatus] || statusColors.default;
};

export {
  getInputClasses,
  convertJsonToFormData,
  setToOptionModel,
  reOrderDnd,
  mapContentSectionName,
  copyToClipBoard,
  countdownMessage,
  dateFilterListRenderer,
  dateFilterLogic,
  extractDateFromString,
  extractDate,
  extractTimeFromString,
  extractLocationData,
  extractDescription,
  getMembershipOptions,
  getEventStatus,
  getEventStatusColor,
};