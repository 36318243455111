/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// ASSETS
import { TbCircleCheckFilled } from 'react-icons/tb';
// COMPONENTS
import { Modal, Button } from 'react-bootstrap';
import { PayPalButtons } from '@paypal/react-paypal-js';
import Confetti from 'react-confetti';
import CustomBlock from '../../../../../components/CustomBlock';
import SelectBlock from '../../../../../components/SelectBlock';
import Overlay from '../../../../../components/Overlay';
// SERVICES & HELPERS
import * as authRedux from '../../../../../redux/authRedux';
import * as alert from '../../../../../redux/alertToastRedux';
import * as subscriptionPlanService from '../../../../../services/subscription/subscriptionPlanService';
import * as subscriptionService from '../../../../../services/subscription/subscriptionService';
import * as userService from '../../../../../services/management/userService';

const subscriptionPlanBenefits = [
  {
    name: 'Engaged Subscriber',
    benefits: [
      'Profile Listed on Engage Pages',
      'Access to Events and Meetings (as defined by admin)',
    ],
  },
  {
    name: 'Engaged Member',
    benefits: [
      'Vetted Referral Partner Status',
      'Profile Listed on Engage Pages',
      'Access to Private Members-Only Pages',
      'Access to Events and Meetings (Members Only and Open)',
      'Charter Member Special Benefits',
    ],
  },
  {
    name: 'Engaged Sponsor',
    benefits: [
      'Vetted Referral Partner Status',
      'Profile Listed on Engage Pages',
      'Access to Private Members-Only Pages',
      'Access to Events and Meetings (Members Only and Open)',
      'Charter Member Special Benefits',
    ],
  },
];

const PayPalSubscriptionModal = (props) => {
  const { show, onClose, auth, membership, onSubscriptionSuccess, onSubscriptionError } = props; // eslint-disable-line
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [currentStep, setCurrentStep] = useState('step1');
  // SUBERSCRIPTION PLAN
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [subscriptionPlanOptions, setSubscriptionPlanOptions] = useState([]);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(null);

  useEffect(() => {
    if (auth) {
      // console.log('AUTH \n', auth);
      setLoggedInUser(auth.user);
    }

    if (membership) {
      fetchSubscriptionPlans();
    }
  }, []);

  const fetchSubscriptionPlans = async () => {
    setIsLoading(true);
    try {
      const data = await subscriptionPlanService.getAllSubscriptionPlans();
      let options = data.map((plan) => ({
        value: plan.planId,
        label: plan.name,
      }));

      // UPDATE PLAN IDS IN DEVELOPMENT ENVIRONMENT TO MATCH PAYPAL SANDBOX
      if (process.env.NODE_ENV === 'development') {
        const paypalSandboxSubscriptionIds = {
          'Engaged Subscriber': 'P-6F091326HD168200JM6DON4Y',
          'Engaged Member': 'P-5KV10544EN190050NM4LKG4A',
          'Engaged Sponsor': 'P-99J22966B6377700AM4LKGII',
        };

        // REPLACE PLAN IDS FOR TESTING PURPOSES
        data.forEach((plan) => {
          if (paypalSandboxSubscriptionIds[plan.name]) {
            plan.planId = paypalSandboxSubscriptionIds[plan.name];
          }
        });

        options = data.map((plan) => ({
          value: plan.planId,
          label: plan.name,
        }));
      }

      if (!membership.isEligible) {
        // FILTER PLAN BASED ON USER ELIGIBILITY
        options = data
          .filter((plan) => plan.name === 'Engaged Subscriber')
          .map((plan) => ({
            value: plan.planId,
            label: plan.name,
          }));
      } else {
        // REMOVE THE PLAN THAT MATCHES THE USER'S CURRENT MEMBERSHIP TIER
        options = data
          .filter((plan) => plan.name !== membership.membershipTier)
          .map((plan) => ({
            value: plan.planId,
            label: plan.name,
          }));
      }

      setSubscriptionPlans(data);
      setSubscriptionPlanOptions(options);
    } catch (ex) {
      showAlert({ text: ex.message, state: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateSubscription = async (data, actions) => {
    setIsProcessing(true);
    try {
      const subscriptionId = await actions.subscription.create({ plan_id: selectedSubscriptionPlan.planId });
      return subscriptionId;
    } catch (error) {
      onSubscriptionError('Error creating subscription. Please try again.');
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleApproval = async (data) => {
    try {
      if (data.orderID) {
        const requestData = {
          userId: loggedInUser.id,
          subscriptionPlanId: selectedSubscriptionPlan.planId,
          subscriptionPlanName: selectedSubscriptionPlan.name,
          paypalTransactionId: data.orderID,
          paypalSubscriptionId: data.subscriptionID,
          updateFlag: false,
          ...data
        };

        await subscriptionService.createSubscription(requestData);
        setCurrentStep('step4');
      }
    } catch (error) {
      onSubscriptionError('Error processing subscription approval.');
    }
  };

  const handleError = (message) => {
    console.error(message);
    showAlert({ text: message, state: 'error' });
  // setShowPayPalSubscriptionModal(false);
  };

  const handleSubscriptionPlanChange = (option) => {
    if (!option) {
      setSelectedSubscriptionPlan(null);
      return;
    }

    const selectedPlan = subscriptionPlans.find((plan) => option.value === plan.planId);
    const selectedPlanWithBenefits = {
      ...selectedPlan,
      benefits: subscriptionPlanBenefits.find(
        (item) => item.name === selectedPlan.name
      )?.benefits || [],
    };

    setSelectedSubscriptionPlan({
      ...selectedPlanWithBenefits,
      value: option.value,
      label: option.label,
    });
  };

  const handleModalClose = () => {
    onClose();
    setSelectedSubscriptionPlan(null);
    setCurrentStep('step1');
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Modal show={show} onHide={handleModalClose} centered>
        <Modal.Header closeButton className="border-0 pb-2">
          <Modal.Title className="size-lg secondary-animated-gradient--clr fw-700">
            {currentStep === 'step1' && 'Memberships'}
            {currentStep === 'step2' && 'Subscription'}
            {currentStep === 'step3' && 'Payment'}
            {currentStep === 'step4' && 'Thank You'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* STEP 1 - GEAUX ENGAGE MEMBERSHIP */}
          {currentStep === 'step1' && (
            <>
              <p className="size-sm dark-grey--clr mb-10">
                Become a Geaux Engage member to access exclusive benefits that help you stay connected and grow within our community, including:
              </p>
              <ul className="list-unstyled size-sm">
                <li className="d-flex align-items-center gy-2 mb-10">
                  <TbCircleCheckFilled size={24} className="flex-shrink-0 success--clr" />
                  <span className="dark-grey--clr">
                    Vetted Referral Partner
                  </span>
                </li>
                <li className="d-flex align-items-center gy-2 mb-10">
                  <TbCircleCheckFilled size={24} className="flex-shrink-0 success--clr" />
                  <span className="dark-grey--clr">
                    Profile listing on Engage Pages
                  </span>
                </li>
                <li className="d-flex align-items-center gy-2 mb-10">
                  <TbCircleCheckFilled size={24} className="flex-shrink-0 success--clr" />
                  <span className="dark-grey--clr">
                    Exclusive meetings and events for members
                  </span>
                </li>
                <li className="d-flex align-items-center gy-2 mb-10">
                  <TbCircleCheckFilled size={24} className="flex-shrink-0 success--clr" />
                  <span className="dark-grey--clr">
                    Priority access to posted messages
                  </span>
                </li>
                <li className="d-flex align-items-center gy-2 mb-10">
                  <TbCircleCheckFilled size={24} className="flex-shrink-0 success--clr" />
                  <span className="dark-grey--clr">
                    And more!
                  </span>
                </li>
              </ul>
            </>
          )}
          {/* STEP 2 - CHOOSE MEMBERSHIP PLAN */}
          {currentStep === 'step2' && (
          <>
            <CustomBlock className="mb-30">
              <SelectBlock
                isRequired
                isClearable
                label="Subscription Plan"
                labelColor="dark--clr"
                placeholder="Select Subscription Plan"
                value={selectedSubscriptionPlan || null}
                onChange={handleSubscriptionPlanChange}
                options={subscriptionPlanOptions}
                inputState={selectedSubscriptionPlan ? 'valid' : 'invalid'}
              />
            </CustomBlock>

            {(!selectedSubscriptionPlan && !membership.isEligible) && (
              <>
                <p className="size-sm dark-grey--clr mb-20">
                  Not eligible for full membership? You can still sign up as a <span className="fw-600">subscriber</span> to start engaging with our community today!
                </p>

                <p className="size-sm dark-grey--clr mb-10">
                  To qualify for full membership:
                </p>

                <ul className="list-unstyled size-sm">
                  <li className="d-flex align-items-center gy-2 mb-10">
                    <TbCircleCheckFilled size={24} className="flex-shrink-0 success--clr" />
                    <span className="dark-grey--clr">
                      Ensure your profile is complete with up-to-date information.
                    </span>
                  </li>
                  <li className="d-flex align-items-center gy-2 mb-10">
                    <TbCircleCheckFilled size={24} className="flex-shrink-0 success--clr" />
                    <span className="dark-grey--clr">
                      Participate in at least one open meeting.
                    </span>
                  </li>
                  <li className="d-flex align-items-center gy-2 mb-10">
                    <TbCircleCheckFilled size={24} className="flex-shrink-0 success--clr" />
                    <span className="dark-grey--clr">
                      <Link to="/contact-geaux-engage" className="fw-600 primary-animated-gradient--clr primary-lighter--hover-underline">Reach out</Link> if you have any questions!
                    </span>
                  </li>
                </ul>
              </>
            )}

            {selectedSubscriptionPlan && (
            <>
              <p className="size-mdlg primary-animated-gradient--clr fw-700 lh-normal mb-10">
                {selectedSubscriptionPlan.name}
              </p>

              <p className="size-sm dark-grey--clr lh-normal mb-10">
                Membership Cost:
                <span className="size-mdlg fw-600 lh-normal">
                  {' '}
                  ${selectedSubscriptionPlan.amount}
                  <span className="size-xs dark-grey--clr fw-400">&#65295;Year</span>
                </span>
              </p>

              <>
                <p className="size-sm dark-grey--clr lh-normal mb-10">
                  Membership Benefits:
                </p>
                <ul className="list-unstyled size-sm mb-3">
                  {selectedSubscriptionPlan.benefits.map((benefit, index) => (
                    <li className="d-flex align-items-center gy-2 mb-10">
                      <TbCircleCheckFilled size={24} className="flex-shrink-0 success--clr" />
                      <span className="lh-normal dark-grey--clr">{benefit}</span>
                    </li>
                  ))}
                </ul>
              </>
            </>
            )}
          </>
          )}
          {/* STEP 3 - MEMBERSHIP PAYMENT */}
          {currentStep === 'step3' && (
          <>
            <p className="size-md dark-grey--clr lh-normal mb-20">
              <span className="primary-animated-gradient--clr fw-700">
                {selectedSubscriptionPlan.name}
              </span>
              <span className="size-md fw-600 lh-normal ml-20">
                ${selectedSubscriptionPlan.amount}
                <span className="size-xs dark-grey--clr fw-400">&#65295;Year</span>
              </span>
            </p>

            {/*
            TESTING INFO FOR PAYPAL SANDBOX:
              - Visa: 4111 1111 1111 1111
              - MasterCard: 5500 0000 0000 0004
              - Amex: 3782 8224 6310 005
              - Discover: 6011 0009 9013 9424

              - Expiry Date: Use any future date (e.g., 12/25)
              - CVV: Use any 3 digits for Visa, MasterCard, Discover (e.g., 123)
              - Use any 4 digits for Amex (e.g., 1234)
            */}

            <PayPalButtons
              style={{
                shape: 'pill',
                layout: 'vertical',
              }}
              createSubscription={handleCreateSubscription}
              onApprove={handleApproval}
              onError={handleError}
            />
          </>
          )}
          {/* STEP 4 - THANK YOU */}
          {currentStep === 'step4' && (
          <>
            <p className="size-mdlg primary-animated-gradient--clr lh-normal fw-700 mb-3">
              Thank you for becoming an {selectedSubscriptionPlan?.name}!
            </p>
            <p className="size-sm grey-animated-gradient--clr fw-500 mb-10">
              Your membership is <span className="primary-animated-gradient--clr">now active</span>, and you’ve unlocked access to benefits tailored to your subscription plan, including exclusive events, resources, and more.
            </p>
            <p className="size-sm grey-animated-gradient--clr fw-500 mb-10">
              We look forward to helping you grow within our community!
            </p>
            <p className="size-sm grey-animated-gradient--clr fw-500 mb-10">
              Keep an eye on your emails or alerts for upcoming announcements, meetings, and opportunities.
            </p>
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              numberOfPieces={500}
              initialVelocityY={5}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: -1,
              }}
            />
          </>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          {currentStep === 'step1' && (
          <>
            <Button variant="secondary" onClick={handleModalClose} className="round border-0">
              Close
            </Button>
            <Button variant="success" onClick={() => setCurrentStep('step2')} className="round border-0">
              Continue
            </Button>
          </>
          )}
          {currentStep === 'step2' && (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setCurrentStep('step1');
                setSelectedSubscriptionPlan(null);
              }}
              className="round border-0"
            >
              Back
            </Button>
            <Button
              variant="success"
              onClick={() => setCurrentStep('step3')}
              className="round border-0"
              disabled={!selectedSubscriptionPlan?.planId}
            >
              Continue
            </Button>
          </>
          )}
          {currentStep === 'step3' && (
          <Button variant="secondary" onClick={() => setCurrentStep('step2')} className="round border-0">
            Back
          </Button>
          )}
          {currentStep === 'step4' && (
          <Button variant="secondary" onClick={handleModalClose} className="round border-0">
            Close
          </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...authRedux.actions, ...alert.actions })(PayPalSubscriptionModal);