/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
// DEPENDENCIES
import React, {
  useState, useRef, useEffect
} from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Fade } from 'react-awesome-reveal';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { registerPlugin } from 'filepond';
import { TbBrandLinkedin, TbWorldWww, TbTrash } from 'react-icons/tb'; // eslint-disable-line
import ReCAPTCHA from 'react-google-recaptcha';
// COMPONENTS
import { Container, Row, Col, Form, Image } from 'react-bootstrap'; // eslint-disable-line
import ContentBlock from '../../../../../components/ContentBlock';
import FormBlock from '../../../../../components/FormBlock';
import InputBlock from '../../../../../components/InputBlock';
import Button from '../../../../../components/Button';
import CustomBlock from '../../../../../components/CustomBlock';
import FileUploadBlock from '../../../../../components/FileUploadBlock';
import TextAreaBlock from '../../../../../components/TextAreaBlock';
import Overlay from '../../../../../components/Overlay';
// ASSETS
import defaultMemberProfileImage from '../../../../../assets/client/default-member-profile-image.png';
import defaultMemberCompanyLogo from '../../../../../assets/client/default-member-company-logo.svg';
// SERVICES AND HELPERS
import * as helper from '../../../../../helpers/helper';
import * as membershipService from '../../../../../services/management/membershipService';
import * as alertRedux from '../../../../../redux/alertToastRedux';
import * as authRedux from '../../../../../redux/authRedux';

registerPlugin(FilePondPluginFileValidateSize);

const initialMembershipProfileValues = {
  id: 0,
  // CONTACT DETAILS
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  // ADDRESS INFORMATION
  companyName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  // PROFILE INFORMATION
  imageFile: null,
  imageFilePath: '',
  companyLogoFile: null,
  companyLogoFilePath: '',
  content: '',
  keywords: '',
  linkedInURL: '',
  websiteURL: '',
};

const MemberDetailsTab = (props) => {
  const { auth, title, membership, refreshMembership, membershipProfile, showAlert } = props; // eslint-disable-line
  const [isLoading, setIsLoading] = useState(false);
  // RECAPTCHA
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  // FILE UPLOAD
  const fileSizeLimit = 1 * 1024 * 1024; // 1 MB
  const filePondRef_imageFile = useRef(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const filePondRef_companyLogoFile = useRef(null);
  const [uploadedCompanyLogo, setUploadedCompanyLogo] = useState(null);

  useEffect(() => {
    if (membershipProfile) {
      formik.setValues(membershipProfile);
    }
  }, [membershipProfile]);

  useEffect(() => {
    if (membership?.imageFilePath) {
      setUploadedImage(null);
      filePondRef_imageFile.current.removeFiles();
    }
  }, [membership]);

  const formik = useFormik({
    initialValues: initialMembershipProfileValues,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('First name is required')
        .max(50, 'First name cannot exceed 50 characters'),
      lastName: Yup.string()
        .required('Last name is required')
        .max(50, 'Last name cannot exceed 50 characters'),
      emailAddress: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      phoneNumber: Yup.string()
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format')
        .nullable(),
      companyName: Yup.string()
        .max(100, 'Company name cannot exceed 100 characters')
        .nullable(),
      city: Yup.string()
        .max(100, 'City cannot exceed 100 characters')
        .nullable(),
      state: Yup.string()
        .nullable()
        .max(2, 'State abbreviation should be 2 characters'),
      zipCode: Yup.string()
        .nullable()
        .matches(/^\d{5}$/, 'Invalid ZIP code format'),
      content: Yup.string()
        .max(1000, 'Content cannot exceed 1000 characters')
        .nullable(),
      keywords: Yup.string()
        .max(500, 'Keywords cannot exceed 500 characters')
        .nullable(),
      linkedInURL: Yup.string()
        .url('Invalid LinkedIn URL')
        .nullable(),
      websiteURL: Yup.string()
        .url('Invalid website URL')
        .nullable(),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      submitForm(values, setSubmitting);
    },
  });

  const submitForm = async (values, setSubmitting) => {
    if (!captchaToken) {
      setSubmitting(false);
      return;
    }

    const payload = {
      ...values,
      reCaptchaToken: captchaToken,
      imageFile: uploadedImage || null,
      companyLogoFile: uploadedCompanyLogo || null,
    };

    const profileResource = convertJsonToFormData(payload);

    try {
      const response = await membershipService.updateMembershipProfile(profileResource);
      showAlert({ text: response.message || 'Profile Updated Successfully', state: 'success' });
      setCaptchaToken(null);
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
      await refreshMembership();
      setUploadedImage(null);
      setUploadedCompanyLogo(null);
      if (filePondRef_imageFile.current) {
        filePondRef_imageFile.current.removeFiles();
      }
      if (filePondRef_companyLogoFile.current) {
        filePondRef_companyLogoFile.current.removeFiles();
      }
    } catch (error) {
      showAlert({ text: error.response?.data?.message || error.message || 'Error saving the member.', state: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const convertJsonToFormData = (json) => {
    const form_data = new FormData();

    Object.entries(json).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => form_data.append(key, item));
      } else if (value instanceof File) {
        form_data.append(key, value);
      } else {
        form_data.append(key, value === null ? '' : value);
      }
    });

    return form_data;
  };

  const handleUpdateFiles = (fileItems) => {
    const file = fileItems[0]?.file;

    if (file && file.size <= fileSizeLimit) {
      setUploadedImage(file);
    }
  };

  const handleUpdateCompanyLogoFiles = (fileItems) => {
    const file = fileItems[0]?.file;

    if (file && file.size <= fileSizeLimit) {
      setUploadedCompanyLogo(file);
    }
  };

  // const removeFilePath = async (fileType) => {
  //   try {
  //     const updatedProfile = { ...membershipProfile };

  //     if (fileType === 'image') {
  //       updatedProfile.imageFilePath = null;
  //     } else if (fileType === 'logo') {
  //       updatedProfile.companyLogoFilePath = null;
  //     }

  //     const payload = {
  //       ...updatedProfile,
  //       reCaptchaToken: captchaToken,
  //       imageFile: uploadedImage || null,
  //       companyLogoFile: uploadedCompanyLogo || null,
  //     };

  //     const profileResource = convertJsonToFormData(payload);

  //     await membershipService.updateMembershipProfile(profileResource);

  //     showAlert({
  //       text: `${fileType === 'image' ? 'Profile image' : 'Company logo'} removed successfully.`,
  //       state: 'success',
  //     });

  //     // Optionally refresh profile or local state
  //     await refreshMembership();
  //   } catch (error) {
  //     showAlert({ text: 'Failed to remove the file.', state: 'error' });
  //   }
  // };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <ContentBlock className="tab-content p-0">
        <CustomBlock className="header primary-lighter--sbg--gradient user-select-none">
          <h4 className="size-xl light-color mx-auto">
            <Fade
              delay={200} cascade
              damping={1e-1}
            >
              {title}
            </Fade>
          </h4>
        </CustomBlock>

        <FormBlock className="content-container--card-style--with-shadow mb-0">
          <Container fluid className="p-0">
            {/* CONTACT DETAILS */}
            <>
              <Fade direction="left" className="pl-0">
                <h3 className="size-mdlg fw-700 primary-lighter--clr mb-20 p-0 subheader">Contact Details</h3>
              </Fade>

              <Row className="mb-40">
                <Col xs={12} sm={6} md={6} xxl={4}>
                  <InputBlock
                    isRequired
                    label="First Name"
                    placeholder="John"
                    errorMessage={formik.touched.firstName && formik.errors.firstName}
                    inputState={`${helper.getInputClasses(formik, 'firstName')}`}
                    {...formik.getFieldProps('firstName')}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} xxl={4}>
                  <InputBlock
                    isRequired
                    label="Last Name"
                    placeholder="Smith"
                    errorMessage={formik.touched.lastName && formik.errors.lastName}
                    inputState={`${helper.getInputClasses(formik, 'lastName')}`}
                    {...formik.getFieldProps('lastName')}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} xxl={4}>
                  <InputBlock
                    label="Phone Number"
                    placeholder="(555) 555-5555"
                    mask="(999) 999-9999"
                    maskChar=""
                    errorMessage={formik.touched.phoneNumber && formik.errors.phoneNumber}
                    inputState={`${helper.getInputClasses(formik, 'phoneNumber')}`}
                    {...formik.getFieldProps('phoneNumber')}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} xxl={4}>
                  <InputBlock
                    isRequired
                    label="Email Address"
                    placeholder="address@email.com"
                    errorMessage={formik.errors.emailAddress}
                    inputState={`${helper.getInputClasses(formik, 'emailAddress')}`}
                    {...formik.getFieldProps('emailAddress')}
                    isDisabled
                    className="disabled border-0"
                  />
                </Col>
              </Row>
            </>

            {/* COMPANY & LOCATION */}
            <>
              <Fade direction="left" className="pl-0">
                <h3 className="size-mdlg fw-700 primary-lighter--clr mb-20 p-0 subheader">Company & Location</h3>
              </Fade>

              <Row className="mb-40">
                <Col xs={12} md={6}>
                  <InputBlock
                    label="Company Name"
                    placeholder="Smith & Co."
                    errorMessage={formik.errors.companyName}
                    inputState={`${helper.getInputClasses(formik, 'companyName')}`}
                    {...formik.getFieldProps('companyName')}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <InputBlock
                    label="Street Address"
                    placeholder="123 Main St"
                    errorMessage={formik.touched.addressLine1 && formik.errors.addressLine1}
                    inputState={`${helper.getInputClasses(formik, 'addressLine1')}`}
                    {...formik.getFieldProps('addressLine1')}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <InputBlock
                    label="Apt/Suite/Unit"
                    placeholder="Apt 1"
                    errorMessage={formik.touched.addressLine2 && formik.errors.addressLine2}
                    inputState={`${helper.getInputClasses(formik, 'addressLine2')}`}
                    {...formik.getFieldProps('addressLine2')}
                  />
                </Col>

                <Col xs={12} md={4}>
                  <InputBlock
                    label="City"
                    placeholder="Baton Rouge"
                    errorMessage={formik.touched.city && formik.errors.city}
                    inputState={`${helper.getInputClasses(formik, 'city')}`}
                    {...formik.getFieldProps('city')}
                  />
                </Col>

                <Col xs={6} md={2}>
                  <InputBlock
                    label="State"
                    placeholder="LA"
                    errorMessage={formik.touched.state && formik.errors.state}
                    inputState={`${helper.getInputClasses(formik, 'state')}`}
                    {...formik.getFieldProps('state')}
                  />
                </Col>

                <Col xs={6} md={2}>
                  <InputBlock
                    label="ZIP/Postcode"
                    placeholder="12345"
                    errorMessage={formik.touched.zipCode && formik.errors.zipCode}
                    inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                    {...formik.getFieldProps('zipCode')}
                  />
                </Col>
              </Row>
            </>

            {/* PROFILE INFORMATION */}
            <>
              <Fade direction="left" className="pl-0">
                <h3 className="size-mdlg fw-700 primary-lighter--clr mb-20 p-0 subheader">Profile Information</h3>
              </Fade>

              {/* PROFILE IMAGE */}
              <Row className="mb-40">
                <Col
                  xs={4} sm={3} md={3} lg={2}
                  xl={2} className="d-flex align-items-center justify-content-center"
                >
                  {membershipProfile.imageFilePath ? (
                    <CustomBlock
                      className="member-profile-image"
                      style={{
                        backgroundImage: membership && membershipProfile.imageFilePath
                          ? `url(${process.env.REACT_APP_API_URL}Attachments${membershipProfile.imageFilePath})`
                          : `url(${defaultMemberProfileImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '300px',
                        width: '100px',
                        height: '100px',
                        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                      }}
                    />
                  ) : (
                    <CustomBlock
                      className="image"
                      style={{
                        background: `url(${defaultMemberProfileImage})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100px',
                        height: '100px',
                        borderRadius: '300px',
                        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                      }}
                    />
                  )}

                </Col>
                <Col
                  xs={8} sm={8} md={8} lg={8}
                  xl={4}
                >
                  <CustomBlock className="input-block-container">
                    <FileUploadBlock
                      inputLabel="Profile Image"
                      inputLabelClassName="static-label dark--clr"
                      containerClassName="filepond-container--label"
                      onupdatefiles={handleUpdateFiles}
                      ref={filePondRef_imageFile}
                      allowFileTypeValidation
                      acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      allowFileSizeValidation
                      maxFileSize="1MB"
                      labelIdle={`
                    <div class="d-flex flex-column">
                      <p class="mid-grey--clr fw-600">Drag & Drop your picture or <span class="filepond--label-action">Browse</span></p>
                      <p class="">File Size Limit: <span class="fw-600">1 MB</span></p>
                      <p class="">Supported File Types: <span class="fw-600">PNG, JPEG, JPG</span></p>
                    </div>`}
                    />
                  </CustomBlock>
                </Col>
              </Row>

              {/* COMPANY LOGO */}
              <Row className="mb-40">
                <Col
                  xs={4} sm={3} md={3} lg={2}
                  xl={2} className="d-flex align-items-center justify-content-center"
                >
                  <CustomBlock className="company-logo-preview position-relative">
                    <img
                      src={
                        membershipProfile.companyLogoFilePath
                          ? `${process.env.REACT_APP_API_URL}Attachments${membershipProfile.companyLogoFilePath}`
                          : defaultMemberCompanyLogo
                      }
                      alt="Company Logo"
                      style={{
                        width: '150px',
                        height: 'auto',
                        maxHeight: '100px',
                        objectFit: 'contain',
                      }}
                    />
                    {/* {membershipProfile.companyLogoFilePath && (
                    <div className="position-absolute remove-image">
                      <Button
                        text={<TbTrash />}
                        className="remove-image"
                        size="xxs"
                        // onClick={() => showConfirmModal({
                        //   title: 'Remove Company Logo',
                        //   text: 'Are you sure you want to remove the company logo?',
                        //   rightBtnText: 'Confirm',
                        //   leftBtnText: 'Cancel',
                        //   btnAction: () => removeFilePath('logo'),
                        // })}
                        onClick={() => removeFilePath('logo')}
                      />
                    </div>
                    )} */}
                  </CustomBlock>
                </Col>
                <Col
                  xs={8} sm={8} md={8} lg={8}
                  xl={4}
                >
                  <CustomBlock className="input-block-container">
                    <FileUploadBlock
                      inputLabel="Company Logo"
                      inputLabelClassName="static-label dark--clr"
                      containerClassName="filepond-container--label"
                      onupdatefiles={handleUpdateCompanyLogoFiles}
                      onremovefile={() => setUploadedCompanyLogo(null)}
                      ref={filePondRef_companyLogoFile}
                      allowFileTypeValidation
                      acceptedFileTypes={['image/svg+xml', 'image/png', 'image/jpeg', 'image/jpg']}
                      allowFileSizeValidation
                      maxFileSize="1MB"
                      labelIdle={`
                      <div class="d-flex flex-column">
                        <p class="mid-grey--clr fw-600">Drag & Drop your company logo or <span class="filepond--label-action">Browse</span></p>
                        <p class="">File Size Limit: <span class="fw-600">1 MB</span></p>
                        <p class="">Supported File Types: <span class="fw-600">SVG (recommended), PNG, JPEG, JPG</span></p>
                      </div>`}
                    />
                  </CustomBlock>
                </Col>
              </Row>

              <Row className="mb-40">
                <Col xs={12}>
                  <TextAreaBlock
                    label="Content"
                    labelColor="mid-grey--clr"
                    placeholder="Enter content you want to share on Engage Pages..."
                    errorMessage={formik.touched.content && formik.errors.content}
                    inputState={`${helper.getInputClasses(formik, 'content')}`}
                    {...formik.getFieldProps('content')}
                  />
                </Col>

                <Col xs={12}>
                  <InputBlock
                    label="Keywords"
                    placeholder="Enter keywords, separated by commas..."
                    errorMessage={formik.touched.keywords && formik.errors.keywords}
                    inputState={`${helper.getInputClasses(formik, 'keywords')}`}
                    {...formik.getFieldProps('keywords')}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <InputBlock
                    label="LinkedIn"
                    iconLeft={<TbBrandLinkedin strokeWidth={1.5} size={24} className="light-grey--clr" />}
                    placeholder="https://www.linkedin.com/in/username"
                    errorMessage={formik.touched.linkedInURL && formik.errors.linkedInURL}
                    inputState={`${helper.getInputClasses(formik, 'linkedInURL')}`}
                    {...formik.getFieldProps('linkedInURL')}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <InputBlock
                    label="Website"
                    iconLeft={<TbWorldWww strokeWidth={1.5} size={24} className="light-grey--clr" />}
                    placeholder="https://www.website.com"
                    errorMessage={formik.touched.websiteURL && formik.errors.websiteURL}
                    inputState={`${helper.getInputClasses(formik, 'websiteURL')}`}
                    {...formik.getFieldProps('websiteURL')}
                  />
                </Col>
              </Row>
            </>
          </Container>

          <CustomBlock className="content-container--actions mt-0 mt-30 gap-3">
            {captchaToken == null && (
              <CustomBlock className="captcha">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={siteKey}
                  onChange={setCaptchaToken}
                  onExpired={() => setCaptchaToken(null)}
                />
              </CustomBlock>
            )}

            <Button
              text="Save Changes"
              className={`btn size-xs fw-400 lh-1 success--bg ${formik.isSubmitting ? 'disabled' : ''}`}
              size="xs"
              onClick={formik.handleSubmit}
              isDisabled={formik.isSubmitting || !captchaToken}
              isLoading={formik.isSubmitting}
            />
          </CustomBlock>
        </FormBlock>

      </ContentBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...authRedux.actions,
  ...alertRedux.actions,
})(MemberDetailsTab);