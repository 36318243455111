/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {
  useState, useRef, useEffect
} from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Fade } from 'react-awesome-reveal';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';
// COMPONENTS
import {
  Container, Row, Col, Form
} from 'react-bootstrap';
import { TbWorldWww } from 'react-icons/tb';
import ContentBlock from '../../../../../components/ContentBlock';
import FormBlock from '../../../../../components/FormBlock';
import InputBlock from '../../../../../components/InputBlock';
import Button from '../../../../../components/Button';
import CustomBlock from '../../../../../components/CustomBlock';
import TextAreaBlock from '../../../../../components/TextAreaBlock';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
// SERVICES AND HELPERS
import * as postService from '../../../../../services/post/postService';
import * as alertRedux from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';

const initialValues = {
  id: 0,
  isActive: true,
  userId: 0,
  content: '',
  link: '',
  linkLabel: '',
  reCaptchaToken: '',
};

const PostsTab = (props) => {
  const { title, auth, showAlert, showConfirmModal, hideConfirmModal } = props; // eslint-disable-line
  // const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userPosts, setUserPosts] = useState([]);
  // RECAPTCHA
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);

  useEffect(() => {
    getUserPosts();
  }, []);

  const getUserPosts = async () => {
    try {
      setIsLoading(true);
      const res = await postService.getPostsByUserId(auth.user.id);
      setUserPosts(res[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: userPosts || initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      content: Yup.string().required('Content is required').max(1000),
      linkLabel: Yup.string().nullable().max(100),
      link: Yup.string().nullable().url('Please enter a valid URL'),
    }),
    onSubmit: async (values) => {
      submitForm(values);
    },
  });

  const submitForm = async (values) => {
    setIsLoading(true);

    if (!captchaToken) {
      setIsLoading(false);
      showAlert({ text: 'Please complete the CAPTCHA', state: 'error' });
      return;
    }

    const postResource = {
      ...values,
      userId: auth.user.id,
      reCaptchaToken: captchaToken,
    };

    try {
      const res = await postService.savePost(postResource);
      showAlert({ text: res.message, state: 'success' });
      getUserPosts();
      setCaptchaToken(null);
    } catch (error) {
      showAlert({ text: error.message || 'Failed to process post', state: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContentBlock className="tab-content p-0">
      <CustomBlock className="header primary-lighter--sbg--gradient user-select-none">
        <h4 className="size-xl light-color mx-auto">
          <Fade delay={200} cascade damping={1e-1}>{title}</Fade>
        </h4>
      </CustomBlock>

      {/* <CustomBlock className="">
        <p className="">{userPosts.content}</p>
      </CustomBlock> */}

      <FormBlock className="content-container--card-style--with-shadow mb-0">
        {/* INTRO SECTION */}
        <CustomBlock className="profile-tab-intro mb-50 xlight--sbg p-3 py-20" style={{ borderRadius: '8px' }}>
          <Fade delay={200} cascade>
            <p className="size-mdlg fw-700 primary-animated-gradient--clr mb-10 lh-normal">
              Connect. Share. Grow.
            </p>
          </Fade>
          <Fade delay={400} cascade direction="up">
            <p className="size-sm mid-grey--clr mb-10">
              Share your thoughts, connect with others, and see your posts featured in the Community Tab — build connections and engagement within your trusted network.
            </p>
            <p className="size-sm mid-grey--clr mb-10">
              {/* eslint-disable-next-line */}
              Community Posts are private and only visible to other <span className="primary-animated-gradient--clr fw-400">Engaged Members</span> and <span className="primary-animated-gradient--clr fw-500">Sponsors</span>.
            </p>
          </Fade>
        </CustomBlock>

        <Container fluid className="p-0">
          <Row className="">
            <Col xs={12}>
              <TextAreaBlock
                label="Content"
                placeholder="Share your message, update, or announcement here..."
                value={formik.values.content}
                errorMessage={formik.touched.content && formik.errors.content}
                inputState={`${formik.touched.content && formik.errors.content ? 'error' : ''}`}
                {...formik.getFieldProps('content')}
                labelColor="dark--clr"
              />
            </Col>
          </Row>

          <Row className="">
            <Col sm={12} md={4} xl={4}>
              <InputBlock
                label="Link Label"
                placeholder="Link Title (eg. Upcoming Workshop)"
                value={formik.values.linkLabel}
                iconLeft={<TbWorldWww strokeWidth={1.5} size={24} className="light-grey--clr" />}
                errorMessage={formik.touched.linkLabel && formik.errors.linkLabel}
                inputState={`${formik.touched.linkLabel && formik.errors.linkLabel ? 'error' : ''}`}
                {...formik.getFieldProps('linkLabel')}
              />
            </Col>

            <Col sm={12} md={8} xl={8}>
              <InputBlock
                label="Link URL"
                placeholder="Enter URL (eg. https://mywebsite.com/workshop)"
                value={formik.values.link}
                iconLeft={<TbWorldWww strokeWidth={1.5} size={24} className="light-grey--clr" />}
                errorMessage={formik.touched.link && formik.errors.link}
                inputState={`${formik.touched.link && formik.errors.link ? 'error' : ''}`}
                {...formik.getFieldProps('link')}
              />
            </Col>
          </Row>

          <Row className="mt-10 mb-40">
            <Col xs={12}>
              <CheckboxBlock
                id="isActive"
                label={formik.values.isActive ? 'Post Is Currently Active' : 'Post Is Currently Hidden'}
                labelClassName="dark--clr lh-normal"
                errorMessage={formik.touched.isActive && formik.errors.isActive}
                inputState={`${formik.touched.isActive && formik.errors.isActive ? 'error' : ''}`}
                isChecked={formik.values.isActive}
                onChange={() => formik.setFieldValue('isActive', !formik.values.isActive)}
              />
            </Col>
          </Row>
        </Container>

        <CustomBlock className="content-container--actions mt-0 mt-30 gap-2">
          {captchaToken == null && (
            <CustomBlock className="captcha">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={siteKey}
                onChange={setCaptchaToken}
                onExpired={() => setCaptchaToken(null)}
              />
            </CustomBlock>
          )}

          {!userPosts ? (
            <Button
              text="Post Message"
              className={`btn size-xs fw-400 lh-1 success--bg ${formik.isSubmitting ? 'disabled' : ''}`}
              size="xs"
              onClick={formik.handleSubmit}
              isDisabled={formik.isSubmitting || !captchaToken}
              isLoading={formik.isSubmitting}
            />
          ) : (
            <>
              <Button
                text="Save Changes"
                className={`btn size-xs fw-400 lh-1 success--bg m-0 ${formik.isSubmitting ? 'disabled' : ''}`}
                size="xs"
                onClick={formik.handleSubmit}
                isDisabled={formik.isSubmitting || !captchaToken}
                isLoading={formik.isSubmitting}
              />

              <Button
                text="Delete Post"
                className="btn size-xs fw-400 lh-1 danger--bg m-0"
                size="xs"
                onClick={() => showConfirmModal({
                  title: 'Delete Post',
                  text: 'Are you sure you want to delete this post?',
                  rightBtnText: 'Confirm',
                  btnAction: async () => {
                    try {
                      const res = await postService.deletePost(userPosts.id);
                      showAlert({ text: res.message, state: 'success' });
                      await getUserPosts();
                      hideConfirmModal();
                    } catch (error) {
                      showAlert({
                        text: error.message || 'An error occurred while deleting the post.',
                        state: 'error',
                      });
                    } finally {
                      setIsLoading(false);
                    }
                  },
                })}
                isDisabled={!captchaToken}
              />
            </>
          )}
        </CustomBlock>
      </FormBlock>
    </ContentBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...alertRedux.actions, ...confirmModal.actions })(PostsTab);