import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Memberships/';

const getAllMemberships = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetMemberships`, constants.REQUIRE_INTERCEPTORS);

const getMembershipById = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetMembershipById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const getMembershipByUserId = async (userId) => axiosInstance.get(`${ENDPOINTORIGIN}GetMembershipByUserId?userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

const submitMembership = async (membership) => axiosInstance.post(`${ENDPOINTORIGIN}CreateUserMembership`, membership, constants.REQUIRE_INTERCEPTORS);

const updateMembership = async (membership) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateUserMembership`, membership, constants.REQUIRE_INTERCEPTORS);

const saveMemberProfile = async (profile) => axiosInstance.put(`${ENDPOINTORIGIN}SaveMemberProfile`, profile, constants.REQUIRE_INTERCEPTORS);

const adminSaveUserMembership = async (membership) => axiosInstance.post(`${ENDPOINTORIGIN}AdminSaveUserMembership`, membership, constants.REQUIRE_INTERCEPTORS);

const getLoggedInUserMembership = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetLoggedInUserMembership`, constants.REQUIRE_INTERCEPTORS);

const isUserEmailDuplicate = async (emailAddress, memberId) => axiosInstance.get(`${ENDPOINTORIGIN}IsUserEmailDuplicate?emailAddress=${emailAddress}&memberId=${memberId}`, constants.REQUIRE_INTERCEPTORS);

const deleteMember = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteMember?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const changeMemberPassword = async (userId, newPassword) => axiosInstance.put(`${ENDPOINTORIGIN}ResetMemberPassword?userId=${userId}&newPassword=${newPassword}`, {}, constants.REQUIRE_INTERCEPTORS);

const emailExists = async (email) => axiosInstance.get(`${ENDPOINTORIGIN}EmailExists?email=${email}`, constants.REQUIRE_INTERCEPTORS);

const getMembershipProfileByUserId = async (userId) => axiosInstance.get(`${ENDPOINTORIGIN}GetMembershipProfileByUserId?userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

const updateMembershipProfile = async (updatedMembership) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateMembershipProfile`, updatedMembership, constants.REQUIRE_INTERCEPTORS);

export {
  getAllMemberships,
  getMembershipById,
  getMembershipByUserId,
  submitMembership,
  updateMembership,
  saveMemberProfile,
  adminSaveUserMembership,
  getLoggedInUserMembership,
  isUserEmailDuplicate,
  deleteMember,
  changeMemberPassword,
  emailExists,
  getMembershipProfileByUserId,
  updateMembershipProfile,
};